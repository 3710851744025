import React from 'react'
export default ({logo}) => {
    return <td valign="top">
    <table cellPadding={0} cellSpacing={0} role="presentation">
        <tbody>
            <tr>
                <td style={{ paddingRight: 10 }}>
                    <img
                        width={logo.width}
                        src={logo.url}
                        border={0}
                        alt="avatar"
                        style={{ width: logo.width, borderRadius: 0 }}
                    />
                </td>
            </tr>
        </tbody>
    </table>
</td>
}