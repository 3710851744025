import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import Signature from "./components/signature";
import Form from "./components/form";
import ClipboardJS from "clipboard";
import "./index.scss";
import logo from "./images/logo.png";

function App() {
  const [formData, setFormData] = useState({
    logo: {
      width: 90,
      url: logo,
    },
    name: "First Last",
    title: "Customer Service",
    companyName: "Phone IQ Inc.",
    officePhone: "+1.555.231.4567",
    mobilePhone: "+1.555.231.4567",
    email: "firstlast@example.test",
    addressLineOne: "",
    addressLineTwo: "", // Not currently in use
    addressState: "",
    addressCity: "",
    addressZip: "",
    website: {
      url: "https://phoneiq.co",
      title: "phoneiq.co",
    },
  });

  function handleInputChange(el) {
    setFormData({
      ...formData,
      [el.name]: el.value,
    });
  }

  useEffect(() => {
    const copiedContent = new ClipboardJS("#emailSignatureFormButton", {
      "text/html": () => document.querySelector("#emailSignature").innerHTML,
    });

    copiedContent.on("success", function (e) {
      console.log(e);
    });

    copiedContent.on("error", function (e) {
      console.log(e);
    });

    // Clean up the event listeners
    return () => {
      copiedContent.destroy();
    };
  }, []);

  return (
    <div className="wrapper">
      <button
        onClick={(ev) => document.body.classList.toggle("dev-mode")}
        className="dev-email-tester"
      >
        ⚙️
      </button>
      <section className="primary">
        <Form handleInputChange={handleInputChange} formData={formData} />
      </section>
      <section className="secondary">
        <Signature formData={formData} />
      </section>
    </div>
  );
}

// Using createRoot for React 18
const root = createRoot(document.getElementById("root"));
root.render(<App />);
