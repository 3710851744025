import React from 'react'
export default ({ fontFamily, colors, companyName }) => {
    return <tr>
    <td valign="top" style={{ fontSize: 0 }}>
        <p
            style={{
                fontWeight: 600,
                color: colors.darkGray,
                fontSize: 12,
                fontFamily,
                margin: "4px 0 0 0",
                lineHeight: "120%",
                display: "block"
            }}
        >{companyName}</p>
    </td>
</tr>
}