import React from 'react'
export default ({ fontFamily, colors, name, title }) => {
    return <tr>
    <td valign="top" style={{ fontSize: 0 }}>
        <p>
            <span style={{
                fontWeight: 600,
                fontSize: 14,
                fontFamily,
                margin: 0,
                lineHeight: "120%",
            }}>{name} </span>
            <span style={{
                fontSize: 11,
                fontFamily,
                margin: 0,
                lineHeight: "150%",
                color: colors.darkGray,
            }}>{title}</span>
        </p>
    </td>
</tr>
}