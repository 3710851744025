import React from 'react'
export default ({ fontFamily, colors, website: {url, title} }) => {
    return <tr>
    <td valign="top" style={{ fontSize: 0 }} height="30">
        <p
            style={{
                fontSize: 11,
                fontFamily,
                margin: 0,
                lineHeight: "120%",
                display: "block"
            }}
        >
            <a
                href={url}
                target="_blank"
                style={{
                    textDecoration: "none",
                    color: colors.blue,
                    fontWeight: 600,
                }}
            >{title}</a>
        </p>
    </td>
</tr>
}