import React from "react";
import { useForm } from "react-hook-form";
import "./form.scss";

export default function Form({ handleInputChange, formData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const renderField = (name, label, required = false) => {
    return (
      <label htmlFor={name}>
        <span>{label}</span>
        <input
          id={name}
          name={name}
          defaultValue={formData[name]}
          {...register(name, { required })}
        />
        {required && errors[name] && <span>This field is required</span>}
      </label>
    );
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <React.Fragment>
      <form
        className="email-signature-form"
        onSubmit={handleSubmit(onSubmit)}
        onChange={(ev) => handleInputChange(ev.target)}
      >
        {renderField("name", "Name", true)}
        {renderField("title", "Title")}
        {renderField("officePhone", "Office Phone")}
        {renderField("mobilePhone", "Mobile Phone")}
        {renderField("email", "Email")}
        {/* {renderField("companyName", "Company Name (or add location)")} */}
        {/* {renderField("addressLineOne", "Address: Line One")}
        {renderField("addressState", "Address: State")}
        {renderField("addressCity", "Address: City")}
        {renderField("addressZip", "Address: Zip")} */}
        <div className="email-signature-form__footer">
          <button
            id="emailSignatureFormButton"
            data-clipboard-target="#emailSignature"
          >
            Copy Signature
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}
