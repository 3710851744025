import React from "react";
import PhoneNumbers from "./phoneNumbers";
import Email from "./email";
import Website from "./website";
import PersonAndTitle from "./personAndTitle";
import CompanyName from "./companyName";
import Address from "./address";
import Logo from "./logo";

const fontFamily = "Trebuchet MS,sans-serif";
const colors = {
  dark: "#000000",
  darkGray: "#666667",
  blue: "#036A9E",
};

export default function Signature({ formData }) {
  const {
    logo,
    title,
    officePhone,
    mobilePhone,
    email,
    companyName,
    name,
    addressLineOne,
    addressLineTwo,
    addressState,
    addressCity,
    addressZip,
    website,
  } = formData;

  return (
    <section className="email-signature" data-clipboard-target>
      <table
        id="emailSignature"
        cellPadding={0}
        cellSpacing={0}
        role="presentation"
        width={550}
        className="wrapper"
      >
        <tbody>
          <tr height="0">
            <td style={{ color: "#ccc", height: 50 }}>
              __________________________________________________
            </td>
          </tr>
          <tr>
            <td>
              <table cellPadding={0} cellSpacing={0} role="presentation">
                <tbody>
                  <tr>
                    {logo && <Logo logo={logo} />}
                    <td valign="top" width="100%">
                      <table
                        cellPadding={0}
                        cellSpacing={0}
                        role="presentation"
                      >
                        <tbody>
                          {name && title && (
                            <PersonAndTitle
                              fontFamily={fontFamily}
                              colors={colors}
                              {...formData}
                            />
                          )}
                          {(officePhone || mobilePhone) && (
                            <PhoneNumbers
                              fontFamily={fontFamily}
                              colors={colors}
                              mobilePhone={mobilePhone}
                              officePhone={officePhone}
                            />
                          )}
                          {email && (
                            <Email
                              fontFamily={fontFamily}
                              colors={colors}
                              {...formData}
                            />
                          )}
                          {companyName && (
                            <CompanyName
                              fontFamily={fontFamily}
                              colors={colors}
                              {...formData}
                            />
                          )}
                          {(addressLineOne ||
                            addressLineTwo ||
                            addressState ||
                            addressCity ||
                            addressZip) && (
                            <Address
                              fontFamily={fontFamily}
                              colors={colors}
                              address={formData}
                            />
                          )}
                          {website && (
                            <Website
                              fontFamily={fontFamily}
                              colors={colors}
                              {...formData}
                            />
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
