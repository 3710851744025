import React from 'react'
export default ({
    fontFamily,
    colors,
    address,
    address: {
        addressLineOne,
        addressLineTwo,
        addressState,
        addressCity,
        addressZip
    }
}) => {
    return <React.Fragment>
        <tr>
            <td valign="top" style={{ fontSize: 0 }}>
                <p
                    style={{
                        color: colors.darkGray,
                        fontSize: 11,
                        fontFamily,
                        margin: 0,
                        lineHeight: "120%",
                        display: "block"
                    }}
                >{addressLineOne}</p>
            </td>
        </tr>
        <tr>
            <td valign="top" style={{ fontSize: 0 }}>
                <p
                    style={{
                        color: colors.darkGray,
                        fontSize: 11,
                        fontFamily,
                        margin: 0,
                        lineHeight: "150%",
                        display: "block"
                    }}
                >{addressCity}, {addressState} {addressZip}</p>
            </td>
        </tr>
    </React.Fragment>
}