import React from "react";
export default ({ fontFamily, colors, officePhone, mobilePhone }) => {
  return (
    <tr>
      <td valign="top" style={{ fontSize: 0 }}>
        {/* if officePhone isn't empty */}
        {officePhone && (
          <p
            style={{
              fontWeight: 600,
              color: colors.darkGray,
              fontSize: 12,
              fontFamily,
              margin: 0,
              lineHeight: "120%",
              display: "block",
            }}
          >
            <span
              style={{
                paddingRight: 0,
                fontWeight: 600,
                color: colors.blue,
              }}
            >
              O:{" "}
            </span>
            <span>{officePhone}</span>
          </p>
        )}
        {/* if mobilePhone isn't empty */}
        {mobilePhone && (
          <p
            style={{
              fontWeight: 600,
              color: colors.darkGray,
              fontSize: 12,
              fontFamily,
              margin: 0,
              lineHeight: "120%",
              display: "block",
            }}
          >
            <span
              style={{
                paddingRight: 0,
                fontWeight: 600,
                color: colors.blue,
              }}
            >
              M:{" "}
            </span>
            <span>{mobilePhone}</span>
          </p>
        )}
      </td>
    </tr>
  );
};
